import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import * as D3 from 'd3-scale-chromatic';
import * as Highcharts from 'highcharts';
import HC_Stock from 'highcharts/modules/stock';
import {
  cloneDeep,
  isEqual,
  isNil,
  isNumber,
  maxBy,
  minBy,
  uniqBy
} from 'lodash';
import moment from 'moment';
import { CardChartArgs } from '@sonar/shared/cards/mt-card-chart/card-chart-args';
import { CardChartMetadata } from '@sonar/shared/cards/mt-card-chart/card-chart-metadata';
import { FarmType } from '@sonar/shared/farm/farm-type';
import { Legend } from './legend';
import { OverviewScope } from '@sonar/shared/overview-scope';
import { ChartNavigatedArgs } from './chart-navigated-args';
import { SelectorButtonChart } from './selector-button-chart';
import { AxisSettings, ChartSettings } from './chart-settings';
import { SonarDataType } from '@sonar/shared/sensor-type';
import { KPIFormattedService } from '@sonar/core/kpis/kpi-formatted.service';
import { KPIFormattedType } from '@sonar/core/kpis/kpi-formatted-type';

HC_Stock(Highcharts);
@Component({
  selector: 'interactive-chart',
  providers: [KPIFormattedService],
  styleUrls: ['./interactive-chart.component.scss'],
  templateUrl: './interactive-chart.component.html',
})
export class InteractiveChartComponent implements OnChanges, OnDestroy {
  @ViewChild('highchartsContent') highchartsContent: ElementRef;

  private isCapacitor = Capacitor.isNativePlatform();
  private languageCode: string;
  private maxDatesToShowHourlyData = 3;
  private rangeSelectorButtons: SelectorButtonChart[];
  private targetChart: Highcharts.Chart;

  constructor(
    private readonly kpiFormattedService: KPIFormattedService,
    private readonly renderer: Renderer2,
    private readonly translateService: TranslateService
  ) {}

  @Input() cardChartArgs: CardChartArgs;
  @Input() cardChartMetadata: CardChartMetadata;
  @Input() chartSettings: ChartSettings;
  @Input() enableInteractions = true;
  @Input() loaded = false;
  @Input() orientation: 'landscape' | 'portrait' = 'portrait';
  @Input() showFullScreen = false;
  @Input() showFullScreenLegends = false;

  @Output() chartNavigated: EventEmitter<ChartNavigatedArgs> =
    new EventEmitter<ChartNavigatedArgs>();
  @Output() fullScreenLegendsClosed: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  axisSettings: AxisSettings;
  chartOptions: Highcharts.Options;
  customLegends: Legend[];
  farmType: number;
  Highcharts: typeof Highcharts = Highcharts;
  legendsLength: number;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['chartSettings'] && this.chartSettings) {
      if (!this.chartSettings?.axisSettings) {
        this.chartSettings.axisSettings =
          this.loadDefaultAxisSettings() as AxisSettings;
      }
      this.axisSettings = this.chartSettings.axisSettings;
    }
    if (changes['cardChartArgs'] && this.cardChartArgs) {
      if (!this.chartSettings) {
        return;
      }
      this.farmType = this.cardChartArgs.dataSource.type;
      await this.initChart();
    }
    if (changes['showFullScreenLegends'] || changes['orientation']) {
      setTimeout(() => {
        if (this.targetChart) {
          this.reflow();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.loaded = false;
    this.cardChartArgs = null;
    this.cardChartMetadata = null;
    this.chartOptions = null;
    this.chartSettings = null;
  }

  redraw(isAnimation: boolean): void {
    this.targetChart.redraw(isAnimation);
  }

  reflow(): void {
    this.targetChart.reflow();
  }

  setChartInstance(chart: Highcharts.Chart): void {
    this.targetChart = chart;
  }

  toggleLoading(toggle: boolean): void {
    if (!this.targetChart) {
      return;
    }
    if (toggle) {
      this.targetChart.showLoading('Loading data from server...');
    } else {
      this.targetChart.hideLoading();
    }
  }

  async onCustomLegendClick(legend: Legend): Promise<void> {
    legend.selected = !legend.selected;
    const options = cloneDeep(this.chartOptions);
    const data = await this.generateChartSeries(
      this.cardChartArgs?.dataSource?.chartData.filter((d) =>
        d.data.some((data) => data.yValue)
      )
    );
    options.series = data;
    this.chartOptions = options;
    const targetSeries = this.targetChart.series[legend.index];
    if (targetSeries.visible) {
      targetSeries.hide();
    } else {
      targetSeries.show();
    }
  }

  async updateData(data: any[]): Promise<void> {
    if (!data?.length || !this.targetChart) {
      return;
    }
    const chartData = await this.generateChartSeries(data);
    if (isEqual(this.targetChart.series, this.cardChartArgs)) {
      return;
    }
    chartData.forEach((s, i) => {
      this.targetChart.series[i]?.setData(s.data, false, false, false);
    });
    this.toggleLoading(true);
    this.redraw(true);
    this.reflow();
    this.toggleLoading(false);
  }

  async onFullScreenLegendsClose(): Promise<void> {
    this.showFullScreenLegends = false;
    this.fullScreenLegendsClosed.emit(this.showFullScreenLegends);
    if (this.orientation === 'portrait') {
      return;
    }
    this.renderer.removeClass(
      this.highchartsContent.nativeElement,
      'highcharts__chart-legends-open'
    );
    setTimeout(() => {
      this.redraw(false);
      this.reflow();
    });
  }

  private loadDefaultAxisSettings(): AxisSettings {
    return {
      xAxis: {
        format: '{value:.0f}',
        showAxes: true,
        type: 'datetime'
      },
      yAxis: {
        format: '{value:.2f}',
        showAxes: true,
        type: 'linear'
      }
    };
  }

  private async initChart(): Promise<void> {
    this.rangeSelectorButtons = await this.setRangeSelectorButtons();
    const chartData = await this.generateChartSeries(
      this.cardChartArgs?.dataSource?.chartData.filter((d) =>
        d.data.some((data) => data.yValue)
      )
    );
    let seriesData = [];
    chartData.forEach((d) => {
      d.data.forEach((c) => seriesData.push(c));
    });
    const minXValue = minBy(seriesData, 'x')?.x;
    const maxXValue = maxBy(seriesData, 'x')?.x;
    const zoomType = this.enableInteractions ? 'x' : undefined;
    const xAxisTranslatedLabel = this.translateLabel(
      this.chartSettings?.primaryAxisLabels?.xAxis
    );
    const yAxisTranslatedLabel = this.translateLabel(
      this.chartSettings?.primaryAxisLabels?.yAxis
    );
    const chartOptions: Highcharts.Options = {
      chart: {
        events: {
          load: (event: any) => {
            const chart = event.target;
            this.setCustomLegends(chart);
            this.legendsLength = chart.series.length + 1;
            setTimeout(() => {
              if (this.enableInteractions) {
                chart.rangeSelector.clickButton(1);
              } else {
                this.reflow();
              }
            });
          },
          render: (event: any) => {
            const chart = event.target;
            this.renderResetZoomButton(chart);
          },
        },
        panKey: 'shift',
        panning: {
          enabled: this.enableInteractions,
          type: zoomType,
        },
        pinchType: zoomType,
        zoomBySingleTouch: !this.isCapacitor,
        zoomType,
      },
      credits: {
        enabled: false,
      },
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
      legend: {
        enabled: false,
      },
      navigator: {
        adaptToUpdatedData: false,
        enabled: false,
      },
      rangeSelector: {
        buttons: this.enableInteractions
          ? this.rangeSelectorButtons
          : undefined,
        enabled: this.enableInteractions,
        inputEnabled: false,
      },
      scrollbar: {
        enabled: this.showFullScreen && !this.isCapacitor,
      },
      series: chartData?.length
        ? chartData
        : [
            {
              color: '#fff',
              data: [0],
              name: undefined,
              opacity: 0,
              type: 'line',
            },
          ],
      tooltip: {
        enabled: !!chartData?.length && this.enableInteractions,
        followTouchMove: false,
        headerFormat: '',
        outside: this.chartSettings?.isSensorChart,
        pointFormat: '{point.custom.label}',
        split: true
      },
      xAxis: {
        events: {
          afterSetExtremes: (event: any) => {
            this.chartNavigate(event);
            this.toggleResetButton(event);
          },
        },
        labels: {
          enabled: true,
          format: this.axisSettings?.xAxis?.format,
          formatter: this.axisSettings?.xAxis?.formatter
            ? this.axisSettings?.xAxis?.formatter
            : (args: any) => {
              const _chartData = this.cardChartArgs?.dataSource?.chartData;
              if (!_chartData?.length) {
                return;
              }
              if (this.axisSettings?.xAxis?.type !== 'datetime') {
                const decimalAge = args.value / 7;
                return this.farmType === FarmType.BIM
                  ? this.getAgeInWeeks(this.farmType, decimalAge)
                  : args.value.toString();
              }
              const date = moment(args.value, 'x')
                .utc(false)
                .format('YYYY-MM-DDTHH:mm:ss');
              let age = undefined;
              _chartData.forEach((a) => {
                const data = a.data.find((d) => d.xValue == date);
                if (!data) {
                  return;
                }
                age = data.age;
              });
              const extremes = args.axis.getExtremes();
              const userMin = moment(extremes.userMin, 'x')
                .utc(false)
                .format('YYYY-MM-DDTHH:mm:ss');
              const userMax = moment(extremes.userMax, 'x')
                .utc(false)
                .format('YYYY-MM-DDTHH:mm:ss');
              let difference: any = undefined;
              if (moment(new Date(userMax)).isValid() && moment(new Date(userMin)).isValid()) {
                difference = moment(userMax).diff(userMin, 'days');
              }
              if (
                !date.endsWith('T00:00:00') ||
                age === undefined ||
                age === null
              ) {
                return moment(args.value, 'x').utc(false).format('h A');
              }
              if (
                age >= 0 &&
                isNumber(difference) &&
                difference <= this.maxDatesToShowHourlyData
              ) {
                return `<span style="color:#0C75F6">${this.getAgeInWeeks(
                  this.farmType,
                  age
                )}D<span>`;
              } else if (age >= 0) {
                return `${this.getAgeInWeeks(this.farmType, age)}<span>D<span>`;
              }
              return undefined;
            }
        },
        min: minXValue,
        max: maxXValue,
        minPadding: 8,
        maxPadding: 8,
        minRange: this.chartSettings?.isSensorChart
          ? 3600 * 1000
          : undefined,
        minTickInterval: this.chartSettings?.isSensorChart
          ? this.axisSettings?.xAxis?.tickInterval
            ? this.axisSettings?.xAxis?.tickInterval
            : 3600 * 1000
          : undefined,
        title: {
          text: xAxisTranslatedLabel || undefined,
          style: {
            fontWeight: '500',
          },
        },
      },
      yAxis: {
        labels: {
          enabled: true,
          format: this.axisSettings?.yAxis?.format,
          formatter: this.axisSettings?.yAxis?.type === 'logarithmic'
            ? this.customYAxisFormatter()
            : undefined,
          padding: 30,
        },
        opposite: false,
        title: {
          text: yAxisTranslatedLabel || undefined,
          style: {
            fontWeight: '500',
          },
        },
      },
    };
    if (
      this.cardChartArgs?.dataSource?.chartData.some((d) => d.secondaryYAxisID)
    ) {
      chartOptions.yAxis = [
        {
          labels: {
            enabled: true,
            format: this.axisSettings?.yAxis?.format,
            formatter: this.axisSettings?.yAxis?.type === 'logarithmic'
              ? this.customYAxisFormatter()
              : undefined,
            padding: 30,
          },
          opposite: false,
          title: {
            text: isNil(this.chartSettings?.primaryYAxisLabel)
              ? undefined
              : this.translateService.instant(
                  this.chartSettings?.primaryYAxisLabel
                ),
            style: {
              fontWeight: '500',
            },
          },
          visible: this.axisSettings?.yAxis?.showAxes,
        },
        {
          labels: {
            enabled: true,
            format: this.axisSettings?.yAxis?.format,
            formatter: this.axisSettings?.yAxis?.type === 'logarithmic'
              ? this.customYAxisFormatter()
              : undefined,
            padding: 30,
          },
          opposite: true,
          title: {
            text: isNil(this.chartSettings?.secondaryYAxisLabel)
              ? undefined
              : this.translateService.instant(
                  this.chartSettings?.secondaryYAxisLabel
                ),
            style: {
              fontWeight: '500',
            },
          },
        },
      ];
    }
    this.chartOptions = chartOptions;
    setTimeout(() => {
      if (!this.targetChart) {
        return;
      }
      this.redraw(true);
    });
  }

  private async setRangeSelectorButtons(): Promise<SelectorButtonChart[]> {
    const oneDayTranslation = await this.translateService.instant('OVERVIEW.1D');
    const lofTranslation = await this.translateService.instant('OVERVIEW.LOF');
    return [
      {
        count: 1,
        overviewScope: OverviewScope.Daily,
        text: oneDayTranslation,
        type: 'day'
      },
      {
        overviewScope: OverviewScope.LOF,
        text: lofTranslation,
        type: 'all',
      },
    ];
  }

  private translateLabel(label: string | undefined): string {
    return !isNil(label)
      ? this.translateService.instant(label)
      : '';
  }

  private async generateChartSeries(chartData: any[]): Promise<any[]> {
    const data = [];
    const colorRangeInfo = {
      colorEnd: 1,
      colorStart: 0,
      useEndAsStart: false,
    };
    const dataPalette = this.interpolateColors(
      chartData?.length,
      D3.interpolateTurbo,
      colorRangeInfo
    );
    const sortChartData = chartData.sort((a, b) => a.label.localeCompare(b.label));
    chartData.forEach((d) => {
      d['legendIndex'] = sortChartData.findIndex((s) => s.label === d.label);
    });
    chartData.forEach((s, i) => {
      const seriesData = this.generateChartData(s.data);
      let color = s.color ? this.hexToRGB(s.color, 1) : dataPalette[i];
      if (data.some((d) => d.color === color)) {
        color = dataPalette[i];
      }
      data.push({
        color,
        connectNulls: true,
        dashStyle: s.dataType === 'data' ? s?.dashStyle : 'ShortDash',
        data: seriesData.map((serie) => {
          serie['custom'] = {
            label: this.getCustomLabel(this.chartSettings?.isSensorChart, serie, s)
          };
          return serie;
        }),
        opacity: 1,
        legendIndex: s.legendIndex,
        name: s.label,
        type: s.type || 'line',
        yAxis: s.secondaryYAxisID ? 1 : 0
      });      
    });
    return data;
  }

  private interpolateColors(dataLength, colorScale, colorRangeInfo): any[] {
    const { colorStart, colorEnd } = colorRangeInfo;
    const colorRange = colorEnd - colorStart;
    const intervalSize = colorRange / dataLength;
    let colorPoint;
    const colorArray = [];

    for (let i = 0; i < dataLength; i++) {
      colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);
      colorArray.push(colorScale(colorPoint));
    }

    return colorArray;
  }

  private calculatePoint(i, intervalSize, colorRangeInfo): number {
    const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
    return useEndAsStart
      ? colorEnd - i * intervalSize
      : colorStart + i * intervalSize;
  }

  private generateChartData(data: any): any[] {
    const seriesData = [];
    const showMarkers = this.chartSettings?.showMarkers;
    data
      .filter((d) => d.yValue !== undefined && d.yValue !== null)
      .forEach((d) => {
        const xData = this.axisSettings?.xAxis?.type === 'datetime'
          ? parseFloat(moment(d.xValue).utc(true).format('x'))
          : d.xValue;
        seriesData.push({
          custom: d.custom,
          label: d.xValue,
          x: xData,
          y: d.yValue,
          marker: {
            enabled: showMarkers && !!d?.marker,
            radius: showMarkers ? d?.marker?.radius : 0,
            fillColor: showMarkers ? d?.marker?.fillColor : undefined,
            symbol: showMarkers ? d?.marker?.symbol : undefined,
          }
        });
      });
    return uniqBy(seriesData, 'x');
  }

  private hexToRGB(hex, alpha): string {
    if (hex === '#000') {
      hex = '#000000';
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  private getCustomLabel(
    isSensorChart: boolean,
    point: any,
    serieData: any
  ): string {
    const minDecimals = this.chartSettings?.tooltipDecimals || 2;
    const maxDecimals = this.chartSettings?.tooltipDecimals || 2;
    const formattedYValue = this.kpiFormattedService.formatDecimal(
      point.y,
      KPIFormattedType.Numeric,
      minDecimals,
      maxDecimals
    );
    const labelDate = moment(point.label, 'YYYY-MM-DDTHH:mm:ss').format(
      'MMM DD, YYYY hh:mm A'
    );
    if (!isSensorChart) {
      const isLabelDate = this.chartSettings?.sonarDataType !== SonarDataType.None;
      return `${isLabelDate
        ? labelDate
        : point.x
      }<br>${serieData.label}: <b>${formattedYValue}</b>`;
    }
    return `<b>${labelDate}</b><br>${serieData.label}: <b>${formattedYValue}</b>`;
  }

  private setCustomLegends(chart: any): void {
    this.customLegends = [];
    this.legendsLength = chart.series.length;
    chart.series.forEach((s) => {
      if (s.name.includes('Navigator')) {
        return;
      }
      const legendIndex = chart.options.series.find(
        (serie) => serie.name === s.name,
      )?.legendIndex;
      if (legendIndex !== undefined && legendIndex >= 0) {
        const legendItem = {
          caption: s.name,
          color: s.color,
          index: s.index,
          isMarker: false,
          legendIndex,
          selected: false
        };
        this.customLegends.push(legendItem);
      }
    });
    this.customLegends.sort((a, b) => a.legendIndex - b.legendIndex);
  }

  private renderResetZoomButton(chart: any): void {
    if (!this.enableInteractions) {
      return;
    }
    if (chart.resetButton) {
      chart.resetButton?.destroy();
    }
    chart.resetButton = chart.renderer.button(
      'Reset zoom',
      null,
      null,
      () => {
        chart.zoomOut();
        chart.rangeSelector.clickButton(1);
      },
      {
        zIndex: 20
      }
    )
    .attr({
      id: 'resetZoom',
      align: 'right',
      title: 'Reset zoom level 1:1'
    })
    .add()
    .align(
      {
        align: 'right',
        x: -15,
        y: 40
      },
      false,
      null
    );
    if (chart.rangeSelector.selected === 1) {
      chart.resetButton?.hide();
    }
  }

  private toggleResetButton(args: any): void {
    const isLOF =
      args.trigger === 'rangeSelectorButton' &&
      args.rangeSelectorButton?.type === 'all';
    const isResetZoom =
      args.trigger === 'zoom' &&
      args.userMin === undefined &&
      args.userMax === undefined;
    if (isLOF || isResetZoom) {
      args.target.chart.resetButton?.hide();
      return;
    }
    const hasExtremes = args.userMin !== undefined && args.userMax !== undefined;
    if (hasExtremes) {
      args.target.chart.resetButton?.show();
    }
  }

  private getAgeInWeeks(farmType: number, age: number): any {
    if (farmType === FarmType.BIM) {
      const weeks = Math.floor(age / 7);
      return (weeks + (age % 7) / 10).toFixed(1);
    }
    return age;
  }

  private customYAxisFormatter(): (args: any) => string {
    switch (this.chartSettings?.sonarDataType) {
      case SonarDataType.RuntimeConsumption:
      case SonarDataType.WaterConsumption:
      case SonarDataType.BinFeedAmount:
      case SonarDataType.Weight:
        return (args) => {
          let precision = 0;
          if (args.value >= 1000) {
            if (args.value % 100) {
              precision = 2;
            } else if (args.value % 1000 && precision < 2) {
              precision = 1;
            }
          }
          if (args.value >= 1000) {
            return `${(args.value / 1000).toFixed(precision)}k`;
          }
          return args.value !== 0
            ? `${
                Math.round(
                  args.value *
                    Math.pow(10, this.chartSettings?.tooltipDecimals || 2)
                ) /
                Math.pow(10, this.chartSettings?.tooltipDecimals || 2)
              }`
            : args.value.toString();
        };      
    }
    return undefined;
  }

  private async chartNavigate(args: any): Promise<void> {
    if (!args.userMin && !args.userMax && args.trigger === 'zoom') {
      await this.updateData(this.cardChartArgs?.dataSource?.chartData);
      return;
    }
    if (!args.userMin && !args.userMax && args.type === 'afterSetExtremes') {
      return;
    }
    const startDate = moment(args.userMin).utc(true).toDate();
    const endDate = moment(args.userMax).utc(true).toDate();
    const rangeDates = { startDate, endDate };
    const overviewScope = args.trigger === 'rangeSelectorButton'
      ? args.rangeSelectorButton.overviewScope
      : undefined;
    this.chartNavigated.emit({ rangeDates, overviewScope } as ChartNavigatedArgs);
  }
}
