import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { MemoryStorageService } from './memory-storage.service';

/* eslint-disable @typescript-eslint/naming-convention */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private auth: AuthService;
  private readonly memoryStorageService: MemoryStorageService;
  constructor(injector: Injector) {
    this.auth = injector.get(AuthService);
    this.memoryStorageService = injector.get(MemoryStorageService);
  }

  #unauthorizedRetries = 3;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      req.url.toLowerCase().includes('onemunters') ||
      req.url.toLowerCase().includes('assets') ||
      req.url.toLowerCase().includes('b2cprovider') ||
      req.url.toLowerCase().includes('appversion')
    ) {
      return next.handle(req);
    }

    return this.handleRequest(req, next, 0);
  }

  handleRequest(req: HttpRequest<any>, next: HttpHandler, retryCount: number) {
    return from(this.auth.getAccessToken()).pipe(
      switchMap((token) => {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status !== 401) {
              return throwError(() => error);
            }

            //This should never be hit, auth.refreshSession() should throw an error if it fails
            //this code just included to prevent infinite loop
            if (retryCount >= this.#unauthorizedRetries) {
              this.unathorizedLogout();
              return throwError(() => error);
            }

            try {
              this.auth.refreshSession();
              return this.handleRequest(req, next, ++retryCount);
            } catch {
              this.unathorizedLogout();
            }

            return throwError(() => error);
          }),
        );
      }),
    );
  }

  unathorizedLogout() {
    this.memoryStorageService.clear();
    this.auth.handleLogoutCallback();
    this.auth.logout();
    
  }
}
